<!-- 封装modal组件 -->
<template>
  <div ref="zc" class="zc" v-NoScroll="visible">
    <a-modal
      :getContainer="() => $refs.zc"
      :visible="visible"
      :width="width"
      :title="title"
      @ok="handleOk"
      @cancel="cancel"
      :destroyOnClose="true"
      :maskClosable="maskClosable"
    >
      <slot name="body"> </slot>

      <template #closeIcon>
        <!-- 给自定义组件提供自定义插槽 -->
        <slot name="cuscloseIcon">
          <img src="@/assets/images/systemicon/closegray.png" alt="" />
        </slot>
      </template>
      <template #footer>
        <slot name="myfooter"></slot>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import { computed, ref, watch, inject } from "vue";
const scroll = inject("scroll");
const props = defineProps({
  title: {},
  width: {},
  visible: {},
  maskClosable: {
    default: false,
  },
});
const emits = defineEmits(["close"]);
// watch(
//   () => props.visible,
//   () => {
//     if (props.visible) {
//       scroll.noScroll();
//     } else {
//       scroll.canScroll();
//     }
//   }
// );

const vNoScroll = (el, binding) => {
  if (binding.value) {
    scroll.noScroll();
  } else {
    scroll.canScroll();
  }
};

function cancel(e) {
  // console.log("cancel",e);
  emits("close", e);
}

function handleOk() {}
</script>
<style lang="scss" scoped>
:deep(.ant-modal) {
  padding: 0;
  color: #fff;
  .ant-modal-content {
    background-color: transparent;
    background-color: #131723;
    button.ant-modal-close {
      width: max-content;
      height: max-content;
      right: .3rem;
      top: .3rem;
      .ant-modal-close-x {
        width: max-content;
        height: max-content;
        line-height: normal;
        // margin-right: 0.31rem;
        @extend %flex-all-center;
      }
    }
    .ant-modal-header {
      color: #fff;
      background-color: transparent;
      padding: 0;
      border-bottom: none;
      .ant-modal-title {
        @extend %flex-all-center;
        height: inherit;
        color: #fff;
      }
    }
    .ant-modal-footer {
      text-align: center;
      padding: 0;
      border-top: none;
    }
    .ant-modal-body {
      padding: 0;
    }
  }
}
</style>
