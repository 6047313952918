import { removeToken, getToken } from "@/utils//auth.js";
import autoAuth from "@/utils/autoAuth.js";
/**
 * 
 * @param {*} bean 金币余额
 * @param {*} diamond 钻石余额
 * @param {*} price 开箱总价
 * @returns 
 */
export default function(bean, diamond, price) {
	bean = parseFloat(bean)
	if (isNaN(diamond)) {
		diamond = 0
	}
	diamond = parseFloat(diamond)
	price = parseFloat(price)
	//计算余额处理方法
  if (!getToken()) {
    removeToken();
    autoAuth();
  } //权限校验

	console.log('付款校验')
	//余额金币足够 直接开箱
	if (bean >= price) {
		console.log('金币充足,直接金币支付')
		return true
	}
	//不用钻石开箱
	// else{
	// 	return false
	// }

	//余额金币不足,金币加钻石开箱
	if (bean < price) {
		const he = bean + diamond
		console.log('he:', he)
		//金币加钻石足够
		if (he >= price) {
			console.log('金币加钻石开箱')
			return 'useDiamond'
		} else {
			//余额金币,钻石不足,需要充值
			if (he == 0) {
				console.log('金币+钻石为零,请充值')
				return false
			}else{
				console.log('余额金币,钻石不为零,但不足开箱,需补差');
				// return 'bc'//补差
				return false
			}
		}

	}
}