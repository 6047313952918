import { getAction, postAction, menuGetAction } from "./menage"


//首充
export const getFirst = () => getAction("/beans/first")

//支付列表
export const getPayList = (params) => getAction("/payList", params)

//实名认证
export const identity = (params) => postAction("/identity", params)

//金币充值
// export const onlineRecharge = (params) => postAction("/recharge", params)
export const onlineRecharge = (params) => postAction("/recharge", params)

//轮播图
export const getBanner = (params) => getAction('/getBanner', params)

//文章列表
export const getNewList = () => getAction('/news/list')

//文章详情
export const getNewDetail = (params) => getAction('/news/detail', params)

//卡密充值
export const kami = (params) => postAction("/card-recharge", params)

//红包活动
export const redActivity = () => getAction("/red")

//获取微信公众号扫码地址
export const wechat = () => getAction('/wechat')

//是否关注公众号
export const subscribe = () => getAction('/wechat/subscribe')


//打开红包
export const openRedbag = (params) => postAction("/red/open", params)

//金币充值记录
export const payRecord = (params) => getAction("/recharge_record", params)

//金币充值订单详情
export const beanDetail = (params) => getAction('/bean/detail', params)

//流水记录
export const flowLogs = (params) => getAction('/flow_logs', params)

//金币列表
export const getCoinBeans = () => getAction("/beans")

export const getAllBagInfo = (params) => getAction("/storage", params)

export const getBagInfo = (params) => getAction("/storage", params)
export const getPerInfo = () => getAction("/me")

//修改用户名
export const setName = (params) => postAction('/set_name', params)

//修改密码
export const reset_password = (params) => postAction('/reset_password', params)

//头像上传
export const base64 = (params) => postAction('/image/base64', params)


//头像更新
export const set_avatar = (params) => postAction('/set_avatar', params)


//修改推广码
export const setInvateCode = (params) => postAction('/set_invite_code', params)

//合作推广
export const promote = () => getAction('/promote')

//优惠券
export const coupon = (params) => postAction('/coupon', params)

//下级列表
export const be_inviter = (params) => getAction('/be_inviter', params)

//修改 Steam 交易地址
export const setSteamUrl = (params) => postAction('/set_steam_url', params)

//steam登录
export const loginSteam = () => getAction('/steam/login')

export const login = (params) => postAction("/login", params)

//发送验证码
export const regSendSms = (params) => postAction("/send_sms", params)

//注册
export const registerCount = (params) => postAction("/register", params)

//短信登录
export const smsLogin = (params) => postAction("/sms_login", params)

//宝箱类别
export const getBoxCategory = () => getAction("/label")

//宝箱列表  参数为宝箱类别的id
export const gethotbox = (params) => getAction("/hotbox", params)

//宝箱详情
export const getBoxDetail = (params) => getAction("/box/detail", params)

//新手开箱
export const newOpen = (params) => postAction('/new_open', params)

//历史掉落
export const getBoxHistoryList = (params) => getAction("/openhistory", params)

//喜报
export const getBulletin = () => getAction("/bulletin")

//开箱
export const handleOpenBox = (params) => postAction("/open", params)

//试玩开箱
export const freeTrial = (params) => getAction("/trial", params)

//饰品商店-商城  获取所有饰品
export const getShopAll = (params) => getAction("/shop", params)

//血战到底详情
export const bloodDetail = (params) => getAction('/xzdd', params)

//血战开箱
export const bloodOpen = (params) => postAction('/xzOpen', params)

//血战开箱记录
export const bloodRecord = (params) => getAction('/xzRecord', params)

//血战到底重置
export const bloodReset = (params) => getAction('/reset', params)

//饰品购买 -- 用钻石
export const buy = (params) => postAction("/shop/buy", params)
//饰品购买 -- 用积分
export const exchange = (params) => postAction("/shop/exchange", params)
//提取奖品
export const extract = (params) => postAction('/extract', params)
//奖品分解
export const getCash = (params) => postAction('/cash', params)


//福利箱--不用了
export const welfare = () => getAction('/welfare')

//活动福利开箱
export const welfareOpen = (params) => postAction('/welfare/open', params)

//roll房间列表
export const getRollRooms = (params) => getAction("/rooms", params)

//个人roll房参加记录
export const userRoomRecord = (params) => getAction("/user/rooms", params)

//roll房详情
export const rollRoomDetail = (params) => getAction("/room/detail", params)

//创建roll房间
export const createRollRoom = (params) => postAction("/room/save", params)

//加入roll房间
export const joinRollRoom = (params) => postAction("/room/join", params)

//饰品列表接口
export const getOrnamentList = (params)=>postAction("/synthesis/storage",params)

//可合成列表
export const getSynthesisList = (params) => getAction('/synthesis/list', params)

//装备合成
export const synthesis = (params) => postAction('/synthesis', params)

//合成记录
export const synthesisRecord = () => getAction('/synthesis/record')

//对战记录
export const arenaAllHistory = (params) => getAction('/arena/history', params)

//我的对战记录
export const arenaMeHistory = (params) => getAction('/arena/me-history', params)

//对战列表
export const arenaList = (params) => getAction('/arena/list', params)
//创盒子对战
export const createArena = (params) => postAction('/arena/create', params)
//对战详情
export const arenadetail = (params) => getAction('/arena/detail', params)

//对战宝箱列表
export const areaBoxList = (params) => getAction('/arena/box-list', params)

//加入盒子竞技频道
export const joinChannel = (params) => postAction('/arena/join-channel', params)

//加入对战
export const joinFight = (params) => postAction('/arena/join', params)

//充值排行榜  榜单 old
export const leaderboards = (params) => getAction('/leaderboards', params)

//充值排行榜 榜单 new
export const top = (params) => getAction('/Top', params)

//验证服务器hash值
export const hashVerify = (params) => getAction('/hashVerify', params)

//获取服务端随机生成的用户种子
export const getUserSeed = () => getAction('/getUserSeed')

//验证开箱结果值
export const seedVerify = (params) => getAction('/seedVerify', params)

export const getInfo = (params) => getAction("/info", params)

//站内信列表
export const site_messageList = (params) => postAction('/message/list',params)

//站内信详情
export const site_messageDetail = (params) => postAction('/message/detail', params)

//站内信操作
export const site_messageHandle = (params) => postAction('/message/handle', params)

export const insert = (params) => postAction("/one/HtContract/insert", params)

export const updateApi = (params) => postAction("/one/HtContract/update", params)

export const deleteApi = (params) => postAction("/one/HtContract/delete", params)


export const rechargeApi = (params) => postAction("/recharge", params)

