<template>
  <div class="wrapper">
    <!-- <div class="control-swiper">
      <div
        类=“btn”
        v-for =“swiperControlData 中的（项目，索引）”
        :key="item.id"
        @click="toggleSwiper(项目)"
      >
        <swiperBtn
          v-bind="swiperControlData[索引]"
          :activeIndex="当前SwiperIndex"
        ></swiperBtn>
      </div>
    </div> -->
    <div class="best" dir="rtl">
      <div class="win-wrapper">
        <div
          class="slide-item"
          @mouseleave="mouseEntered = false"
          @mouseenter="showBox(item, $event)"
          v-for="(item, index) in siteStore.windrop"
          :key="item.id"
        >
          <div class="card-filp award-image">
            <div class="front">
              <div class="xin_bg">
                <img class="award-img" :src="$dimg(item.cover)" alt="" />
                <img class="under-img" :src="swiperBgi(item.lv)" alt="" />
              </div>
              <div class="case-info">
                <!-- {{ index + "-" + item.id }} -->
                <template v-if="$dname(item.hash_name)[0].includes('StatTrak™')">
                  <div class="st-sp">
                    <div class="stClor">{{ hasSt($dname(item.hash_name)[0])[0] }}</div>
                    <div class="gray-name">{{ hasSt($dname(item.hash_name)[0])[1] }}</div>
                  </div>
                </template>
                <div v-else class="gray-name">{{ $dname(item.hash_name)[0] }}</div>
                <div>
                  {{ removeQuality($dname(item.hash_name)[1]) }}
                </div>
              </div>
            </div>
            <div class="back">
              <div class="user-info">
                <img :src="item.user.avatar" alt="" />
                <span>{{ item.user.name }} </span>
              </div>
              <div class="from-box" @click="toBoxDetailPage(item)">
                <img v-if="item.boxImg" :src="item.boxImg" alt="" />
                <img class="from-img" v-if="item.iconpath" :src="item.iconpath" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    

    <!-- <button class="test-btn" @click="testMoveWin">
      {{ "win len;" + siteStore.windrop.length }}
    </button> -->
    <swiper
      ref="mySwiper"
      v-if="slides.data.length > 0"
      @swiper="onswiper"
      @slideChange="slideChange"
      @autoplayStart="autoplayStart"
      dir="rtl"
      :loop="slides.loop"
      :slides-per-view="9"
      :effect="'creative'"
      :simulateTouch="false"
      :grabCursor="true"
      :creativeEffect="{
        prev: {
          opacity: 0,
        },
        next: {
          opacity: 1,
        },
      }"
      :modules="modules"
      :speed="400"
      :spaceBetween="0"
      observer
    >
      <swiper-slide
        v-for="(item, index) in siteStore.hotcase"
        :key="item"
        :virtualIndex="index"
        @mouseleave="mouseEntered = false"
        @mouseenter="showBox(item, $event)"
      >
        <!-- <div class="slide-item">
        {{ 项目.id ? item.id : 项目 }}
      </div> -->
        <div class="slide-item">
          <div class="card-filp award-image">
            <div class="front">
              <div class="xin_bg">
                <img class="award-img" :src="$dimg(item.cover)" alt="" />
                <img class="under-img" :src="swiperBgi(item.lv)" alt="" />
              </div>
              <div class="case-info">
                <template v-if="$dname(item.hash_name)[0].includes('StatTrak™')">
                  <div class="st-sp">
                    <div class="stClor">{{ hasSt($dname(item.hash_name)[0])[0] }}</div>
                    <div class="gray-name">{{ hasSt($dname(item.hash_name)[0])[1] }}</div>
                  </div>
                </template>
                <div v-else class="gray-name">{{ hasSt($dname(item.hash_name)[0]) }}</div>
                <div>
                  {{ removeQuality($dname(item.hash_name)[1]) }}
                </div>
                <!-- {{ siteStore.hotcase?.length + "-" + index }} -->
              </div>
              <!-- <p>
                {{ item.name + abrad(item.dura_alias) + "‎" }}
              </p> -->
              <!-- <p>
                {{“测试”+项目+'-'+索引}}
              </p> -->
            </div>
            <div class="back">
              <div class="user-info">
                <img :src="item.user.avatar" alt="" />
                <span>{{ item.user.name }} </span>
              </div>
              <div class="box-info" @click="toBoxDetailPage(item)">
                <!-- <img :src="item.boxImg" alt="" /> -->
                <img v-if="item.boxImg" :src="item.boxImg" alt="" />
                <img class="from-img" v-if="item.iconpath" :src="item.iconpath" alt="" />
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>

  </div>
</template>
<script setup>
import swiperBtn from "./components/swiperBtn.vue";
// 从“@/store2/bindbox/index.js”导入{hotboxStore}；

import $api from "@/api";
import $store from "@/store";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Virtual } from "swiper";
import { gsap } from "gsap";
import { qualityShotName } from "@/utils/awardQuality.js";

import {
  reactive,
  ref,
  nextTick,
  computed,
  watch,
  onMounted,
  getCurrentInstance,
} from "vue";
import handleResponse from "@/utils/handleResponseData.js";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-creative";
import { swiperBgi } from "@/utils/awardQuality.js";
import { useRouter } from "vue-router";
import {
  awardControl,
  handleAwardItem,
  toRecycle,
  toTake,
  toUpgrade,
  toExchange,
  awardStatus,
  awardTypeIcon,
  awardIconClass,
  toSteam,
  expBarWidth,
  latelyInfo,
  battlePattern,
  getBattles,
} from "@/page/user/skins.js";
const router = useRouter();
const { proxy } = getCurrentInstance();

console.log("swiper 实例;", Swiper);

//前往箱子页面或个人中心页面
function toBoxDetailPage(item) {
  console.log("前往箱子详情页");
  if (item.box_id == 0) {
    router.push(awardTypeIcon.value(item.type).url);
  } else {
    router.push(`/box/${item.box_id}`);
  }
}

const mySwiper = ref(null);
onMounted(() => {
  console.log("swiper dom;", Swiper);
});

function autoplayStart() {}

//去掉道具品质(包括括号)
const removeQuality = computed(() => {
  return (val) => {
    return val.replace(/\s*\([^)]*\)/g, "");
  };
});

//道具名是否包含 StatTrak™
const hasSt = computed(() => {
  return (str) => {
    if (str.includes("StatTrak™")) {
      // return str.replace('StatTrak™','')
      return ["ST", str.replace("StatTrak™", "")];
    }
    return str;
  };
});

// 导入所需模块

const modules = [];

function testMoveWin(count) {
  gsap.to(".win-wrapper", {
    duration: 1,
    x: 1.6 * count + "rem",
    ease: "power2.inOut",
    onComplete: () => {
      console.log("move complete");
      if (siteStore.windrop.length > 3) {
        siteStore.$patch((state) => {
          console.log("store;", state.windrop);
          //优化 页面dom 渲染列表过多
          // state.windrop.shift();
        });
      }
    },
  });
}

const siteStore = $store("siteInfo");
const load3 = ref(false);
const mouseEntered = ref(false);
function showBox(item, e) {
  // console.log("查看箱子图片");

  // 返回

  if (item.box_id == 0) return;
  if (mouseEntered.value) return;
  if (item.boxImg && item.rareAward) {
    // console.log("已有图片");
    return;
  }
  // console.log("请求接口");
  load3.value = true;
  if (item.box_id != 0) {
    //boxid 存在
    mouseEntered.value = true;
    item.boxImg = item.box_cover;
    load3.value = false;

    // $api.blindbox.getBoxImg({ params: { box_id: item.box_id } }).then((res) => {
    //   mouseEntered.value = true;
    //   const resp = handleResponse(res);
    //   if (!resp) return console.log("未知错误", res.message);
    //   item.boxImg = resp[0].cover;
    //   item.rareAward = resp[0].weapon_cover;
    //   load3.value = false;
    // });
  }
}

//计算 轮播图前三个道具的显示
watch(
  () => siteStore.windrop.length,
  (newVal) => {
    console.log("add", newVal);
    const offset = newVal - 3;
    console.log("offset;", offset);

    testMoveWin(offset);
  }
);
watch(
  () => siteStore.hotcase.length,
  async (newVal, oldVal) => {
    console.log("swiper;", proxy.swiper);
    console.log("newVal:", newVal, oldVal);
    const len = newVal - oldVal;

    await nextTick();
    for (let i = 0; i < len; i++) {
      await new Promise((resolve) => {
        setTimeout(() => {
          proxy.swiper.slideNext();
          console.log("moveNext", i);
          if (newVal >= 200) {
            siteStore.hotcase = siteStore.hotcase.slice(-9);
          }
          resolve();
        }, 300); // 300毫秒延迟，可根据实际情况调整
      });
    }
   
  }
);

function nextSlide() {
  nextTick(() => {
    proxy.swiper.slideNext();
  });
}

//Triggered when the current slide changes

const slideChange = (s) => {
  // console.log("swiper-slide:", s.activeIndex, s.previousIndex);
};
console.log("swiper组件:", Swiper);

const onswiper = (swiper) => {
  //swiper组件的实例 调用swiper实例的方法  如停止轮播 开启轮播
  //swiper参数  相当于script脚本 var mySwiper = new Swiper('.swiper',{
  // autoplay : true,   new 的mySwiper 实例})
  // console.log("swiper instance:", swiper);
  // console.log("当前下标;", swiper.activeIndex);
  // console.log('next下标;',swiper.nextIndex);
  console.log("swiper instance;", swiper);
  proxy.swiper = swiper;
  swiper.on("slideChange", () => {
    ``;
    const slides = swiper.el.swiper.slides;
    const sindex = slides[swiper.activeIndex].getAttribute("data-swiper-slide-index");
    console.log("slideChange", swiper.activeIndex);
    console.log("sindex;", sindex);
    slides.forEach((slide, index) => {
      slide.style.border = "none";
    });
    // console.log('dom;',slides[swiper.activeIndex+8].style="border: 2px solid green");
    // slides[swiper.activeIndex + 8].style = "border: 2px solid green";
    // slides[sindex].style = "border: 2px solid green";
    // slides[swiper.activeIndex].style = "border: 2px solid green";


    siteStore.insertIndex = parseInt(sindex) + 8;
    siteStore.currnetIndex = sindex;
    // siteStore.insertIndex = swiper.activeIndex + 8;
    // siteStore.currnetIndex = swiper.activeIndex;
    // console.log('insertIndex;',siteStore.insertIndex);
  });
};

//Data object of the button on the left side of the carousel $myt

const swiperControlData = ref([
  {
    id: 1,
    txt: "ALL",
    imagePath: "systemicon/option.png",
    selectedImagePath: "systemicon/optiona.png",
  },
  {
    id: 2,
    txt: "TOP",
    imagePath: "systemicon/crown.png",
    selectedImagePath: "systemicon/crowna.png",
  },
]);
const currentSwiperIndex = ref(1);
const toggleSwiper = (item) => (currentSwiperIndex.value = item.id);

const swiperBox = reactive({
  data: [],
  loop: true,
});
const slides = ref({
  data: [],
  loop: false,
});
const bgImg = ref(["hui", "hong", "huang", "lan", "zi"]);
const bgXin = ref(["white_sbg", "red_sbg", "blue_sbg", "jin_sbg", "purple_sbg"]);

//Determine whether to display the box image or the source image

function showAwardFrom(list) {
  list.map((item) => {
    const result = item.type != 1 && item.type != 2 && item.type != 3;
    if (result) {
      // There is no box in the prop source. The icon of the corresponding source is returned according to the type.
      item.iconpath = awardTypeIcon.value(item.type).iconpath;
      item.typeDesc = awardTypeIcon.value(item.type).title;
      // console.log("item.iconpath::", item);
    } else {
      //The source of the props has a box. The interface for requesting a picture of the box
      //Mouse move is to request the box image interface
    }
  });
}

//Get unboxing history

function getCaseHistory() {
  $api.blindbox.getBoxHistoryList().then((res) => {
    console.log("开箱历史记录;", res);
    const resp = handleResponse(res);
    if (!resp) return console.log("未知错误", res.message);
    console.log("resp;", resp);
    slides.value.data = resp.historylist.reverse();
    slides.value.win_drop = resp.win_drop;
    showAwardFrom(slides.value.data);
    showAwardFrom(slides.value.win_drop);
    siteStore.setHotcase(slides.value.data, slides.value.win_drop);
    console.log("sl;", slides.value.data.win_drop);
    // Slides.value.data = Array.from({ length: 21 }).map((el, index) => `幻灯片 ${index}`);
    // swiperBox.loop=true
  });
}

getCaseHistory();

// Slides.value = Array.from({length:10}).map((el,index)=>`幻灯片 ${index + 1}`)
// swiperBox.loop=true

//Splicing wear degree

const abrad = computed(() => {
  return (val) => {
    return val == "无" ? "" : `(${val})`;
  };
});

// const hotbox_store = hotboxStore();

// hotbox_store &&
//   hotbox_store.getBoxHistory &&
//   hotbox_store.getBoxHistory().then((res) => {
//     // swiperBox.data = res;
//     // console.log("ppppp", res);
//     // Slides.value = Array.from({length:30}).map((el) => el);
//     Slides.value = res.length > 0 && res.map((el) => el);
//     //最少 25 等数据超过 25 再考虑使用虚拟
//     // Slides.value = Array.from({ length: 21}).map((el, index) => `幻灯片 ${index + 1}`);
//     console.log("幻灯片：",幻灯片.value);

//     // swiperBox.loop=true;//BUG循环有问题
//     // Slides.value = Array.from({length:21}).map((el,index)=>`幻灯片 ${index}`)
//     // swiperBox.loop=true

//     return;
//     // console.log("swiper历史掉落", swiperBox.data)
//     nextTick(() => {
//       swiperBox.loop = true;
//     });
//   });
</script>
<style lang="scss" scoped>
@import "./swiper.scss";
.test-btn {
  position: absolute;
  top: 300px;
}
</style>
