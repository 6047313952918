<!-- 充值弹框 -->
<template>
  <div class="recharge-dialog">
    <myModal
      class="recharge-modal"
      :width="'17rem'"
      :visible="visible"
      @close="close($event)"
    >
      <template #cuscloseIcon>
        <img src="@/assets/images/systemicon/close.png" alt="" />
      </template>
      <template #body>
        <div class="recharge-container">
          <div class="left">
            <div class="title">
              <img class="picon" src="@/assets/images/recharge/rechargeicon.png" alt="" />
              <span>TOP UP BALANCE</span>
            </div>
            <div class="line"></div>
            <div class="list-container">
              <div class="list-title">PAYMENT METHODS</div>
              <div class="list-cards">
                <div
                  class="card-item"
                  :class="{ 'active-card': citem.id === activeCardId }"
                  v-for="citem in paymentList"
                  :key="citem.id"
                >
                  <div class="card" @click="selectCard(citem)">
                    <img
                      class="card-icon"
                      src="@/assets/images/recharge/pay1.png"
                      alt=""
                    />
                    <span>{{ citem.name + ":" }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <!-- 银联 -->
            <div v-if="activeCardId === 3">
              <div class="bank-container">
                <div class="email">
                  <div class="title">Email</div>
                  <div class="input-container">
                    <a-input type="text" placeholder="请输入邮箱" />
                    <a-button>SAVE</a-button>
                  </div>
                </div>
                <div class="amount">
                  <div class="amountleft">
                    <div class="title acol">Amount</div>
                    <div class="input-container acol">
                      <a-input type="number" placeholder="请输入充值金额" />
                    </div>
                    <div class="price-list acol">
                      <a-button v-for="i in 4" :key="i">{{ "$ " + i }}</a-button>
                    </div>
                  </div>
                  <div class="amountright">
                    <div class="title acol">You get</div>
                    <div class="price acol">$25.00+$1.25</div>
                    <div class="info acol">
                      Minimum top-up amount <span class="balance">2.00</span>
                    </div>
                    <div class="pay-btn acol">
                      <a-button @click="handlePay">PAY</a-button>
                    </div>
                  </div>
                </div>
                <div class="promocode">
                  <div class="title col">Promocode</div>
                  <div class="input-container col">
                    <a-input type="text" placeholder="请输入优惠码" />
                    <a-button>SAVE</a-button>
                  </div>
                </div>
              </div>
            </div>

            <!-- 虚拟货币 -->
            <div v-if="activeCardId === 10">
              <div class="virtual-container">
                <template v-if="0">
                  <div class="title">Choose Cryptocurrency</div>
                  <div class="v-coins">
                    <div class="v-coin" v-for="i in 10">
                      <div class="v-img">
                        <img src="@/assets/images/recharge/vbiicon.png" alt="" />
                      </div>
                      <div>Bitcoin</div>
                      <div>BTC</div>
                    </div>
                  </div>
                  <div class="promocode">
                    <div class="title col">Promocode</div>
                    <div class="input-container col">
                      <a-input type="text" placeholder="请输入优惠码" />
                      <a-button>ADD</a-button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="coin-container">
                    <div class="coin-left">
                      <div class="back">
                        <a-space>
                          <left-outlined />
                        </a-space>
                        {{ $t("comeBack") }}
                      </div>
                      <div class="qrcode"></div>
                    </div>
                    <div class="coin-right">
                      <div class="title">
                        <img src="@/assets/images/recharge/vbiicon.png" alt="" />
                        <span class="title">Bitcoin</span>
                      </div>
                      <div class="content">
                        <div class="t1">Copy address</div>
                        <div class="code-link">
                          <span class="gspan">1BvBMSEYstWetqTFn5Au4m4GFg7xJaNVN2</span>
                          <img
                            class="copy-icon"
                            src="@/assets/images/recharge/copyicon.png"
                            alt=""
                          />
                        </div>
                        <div class="info">
                          <img src="@/assets/images/recharge/info.png" alt="" />
                          <span
                            >This is your private depositing address. Any deposit you make
                            will show up in your balance after blockchain confirmation.
                          </span>
                        </div>
                        <div class="title2">Current exchange rate*</div>
                        <div class="code-link">
                          <span>1BTC=</span>
                          <span class="balance">$93811.41</span>
                        </div>
                        <div class="message">
                          <div class="m1">
                            <img src="@/assets/images/recharge/infored.png" alt="" />
                            Before Payment
                          </div>
                          <div class="mt">
                            Minimum deposit equals BTC after the network fees. Depositing
                            less will result in a loss of funds without a possibility for
                            refund.
                          </div>
                          <div class="mt">
                            <span class="note">*Note:</span> if the exchange rate changes
                            during the transaction, you may receive a different amount
                            than the one you sent
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
            </div>

            <!-- 礼品卡 -->
            <div v-if="activeCardId === 4">
              <div class="gift-container">
                <div class="gift-list">
                  <div class="gift" v-for="i in 10">
                    <img src="@/assets/images/recharge/card1.png" alt="" />
                  </div>
                </div>
                <div class="gift-info">
                  Upon clicking on gift card button you will be redirected to a partner
                  site Kinguin.net to complete your purchase.
                </div>
                <div class="promocode">
                  <div class="title col">Promocode</div>
                  <div class="input-container col">
                    <a-input type="text" placeholder="请输入优惠码" />
                    <a-button>ADD</a-button>
                  </div>
                </div>
                <div class="promocode">
                  <div class="title col">Promocode</div>
                  <div class="input-container col">
                    <a-input type="text" placeholder="请输入优惠码" />
                    <a-button>ADD</a-button>
                  </div>
                </div>
              </div>
            </div>

            <!-- skrill  -->
            <div v-if="activeCardId === 11">
              <div class="skrill-container">
                <div class="block">
                  <div class="info">
                    Minimum top-up amount <span class="balance">2.00</span>
                  </div>
                  <div class="btn">
                    <a-button>CHECKOUT</a-button>
                  </div>
                </div>
                <div class="promocode">
                  <div class="title col">Promocode</div>
                  <div class="input-container col">
                    <a-input type="text" placeholder="请输入优惠码" />
                    <a-button>ADD</a-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </myModal>
  </div>
</template>

<script setup>
import { LeftOutlined } from "@ant-design/icons-vue";
import { ref } from "vue";
import eventEmitter from "@/utils/emitter.js";
const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["closed","api:pay"]);

const activeCardId = ref(-1);
function selectCard(item) {
  console.log("item;", item);
  activeCardId.value = item.id;
}

function close(e) {
  console.log('关闭充值弹框;',e);
  emit('closed')
}

//银联充值处理
function handlePay() {
  // eventEmitter.emit("rechargePay");
  console.log("handlePay 银联充值处理");
 const result = Promise.resolve('充值成功');
  result.then(res => {
    console.log('充值成功',res);
    emit('api:pay',res);
  }).catch(err => {
    console.log('充值失败',err);
  });
  return result;
}



//充值方式数组
const paymentList = [
  {
    id: 1,
    name: "支付宝",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 2,
    name: "微信",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 3,
    name: "银行卡",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 4,
    name: "礼品卡",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 5,
    name: "PayPal",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 6,
    name: "Apple Pay",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 7,
    name: "Google Pay",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 8,
    name: "Venmo",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 9,
    name: "Payoneer",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 10,
    name: "Bitcoin",
    icon: "@/assets/images/recharge/pay1.png",
  },
  {
    id: 11,
    name: "skrill",
    icon: "@/assets/images/recharge/pay1.png",
  },
];
</script>
<style lang="scss" scoped>
.active-card {
  @extend %clearBgi;
  background-size: 100% 100%;
  background-image: url("@/assets/images/recharge/selected.png");
  .card {
    border: none !important;
  }
}

.promocode {
  height: 1.5rem;
  background-color: #161c2a;
  border-radius: 0.1rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
  .col {
    width: 100%;
    // border: 2px solid red;
  }
  .input-container {
    margin-top: 0.19rem;
    height: 0.4rem;
    @extend %flex-all-center;
    .ant-input {
      width: 6.87rem;
      margin-right: 0.3rem;
      background-color: transparent;
    }
    .ant-btn {
      width: 1rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      background-color: #443439;
      color: #ffffff;
      font-size: 0.14rem;
      font-weight: bold;
      @extend %flex-all-center;
    }
  }
}

// 银联
.bank-container {
  // border: 2px solid red;
  .email,
  .amountleft,
  .amountright,
  .promocode {
    width: inherit;
    background-color: #161c2a;
  }

  .email {
    height: 1.5rem;
    padding: 0 0.5rem;
    .input-container {
      margin-top: 0.24rem;
      display: flex;
      .ant-input {
        width: 6.8rem;
      }
      .ant-btn {
        width: 1rem;
        height: 0.4rem;
        background-color: $active-color;
        border-radius: 0.2rem;
        font-size: 0.14rem;
        font-weight: bold;
      }
    }
  }
  .amount {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
    height: 2.6rem;
    display: flex;
    justify-content: space-between;
    .amountleft,
    .amountright {
      width: 4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
    }

    .amountleft {
      padding: 0 0.5rem;
      // display: flex;
      // flex-direction: column;
      // align-items: center;
      // justify-content: space-evenly;
      .acol {
        width: 100%;
      }

      .price-list {
        display: flex;

        justify-content: space-evenly;
        .ant-btn {
          width: 0.6rem;
          height: 0.3rem;
          border-radius: 0.15rem;
          background-color: #202737;
          @extend %flex-all-center;
        }
      }
    }
    .amountright {
      .pay-btn {
        .ant-btn {
          width: 1.7rem;
          height: 0.46rem;
          border-radius: 0.23rem;
          @extend %flex-all-center;
          background-color: $active-color;
          color: #ffffff;
          font-size: 0.2rem;
          font-weight: bold;
        }
      }
    }
  }
  .promocode {
    height: 1.5rem;
  }
}

//虚拟货币
.virtual-container {
  border: 2px solid green;
  .title {
    margin-bottom: 0.3rem;
  }
  .v-coins {
    display: grid;
    grid-template-columns: repeat(5, 1.74rem);
    grid-gap: 0.1rem;
    .v-coin {
      cursor: pointer;
      width: 1.74rem;
      height: 1.87rem;
      background-color: #161c2a;
      @extend %flex-all-center;
      flex-direction: column;
      .v-img {
        width: 0.8rem;
        height: 0.8rem;
        img {
          width: 0.8rem;
        }
      }
    }
  }

  .promocode {
    margin-top: 0.2rem;
    border: 2px solid red;
    background-color: #161c2a;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    border-radius: 0.1rem;
    height: 1.5rem;
    .title {
      font-weight: bold;
      font-size: 20px;
      color: #a3b2cf;
    }
  }

  // 虚心币详情
  .coin-container {
    display: flex;
    padding: 0.33rem 0.4rem;
    .coin-left {
      margin-right: 0.4rem;
      width: 2.8rem;
      .back {
        width: 1.3rem;
        height: 0.44rem;
        border-radius: 0.05rem;
        background-color: #272d40;
        @extend %flex-all-center;
      }
      .qrcode {
        width: 2rem;
        height: 2rem;
        border-radius: 0.1rem;
        background-color: #ffffff;
        margin-top: 0.95rem;
      }
    }
    .coin-right {
      .title {
        padding-left: 0.78rem;
        display: flex;
        align-items: center;
        img {
          width: 0.58rem;
        }
        span.title {
          font-size: 0.32rem;
          font-weight: bold;
          color: #dcdefc;
        }
      }
      .content {
        margin-top: 0.5rem;
        .t1 {
          font-size: 0.21rem;
          font-weight: bold;
          color: #dcdefc;
        }
        .code-link {
          margin-top: 0.21rem;
          padding-left: 0.31rem;
          display: flex;
          align-items: center;
          background-color: #1e2637;
          border-radius: 0.1rem;
          height: 0.46rem;
          position: relative;
          .copy-icon {
            right: 0.24rem;
            width: 0.14rem;
            position: absolute;
          }
          .gspan {
            color: #37e629;
          }
        }
        .info {
          font-size: 0.14rem;
          font-weight: 400;
          color: #a3b2cf;
          display: flex;
          align-items: center;
          margin-top: 0.2rem;
          margin-bottom: 0.31rem;
          img {
            width: 0.16rem;
            height: 0.16rem;
            margin-right: 0.1rem;
          }
        }
        .title2 {
          font-weight: 400;
          font-size: 18px;
          color: #dcdefc;
        }
        .message {
          margin-top: 0.32rem;
          .m1 {
            font-weight: 400;
            font-size: 18px;
            color: #dcdefc;
            display: flex;
            align-items: center;
            img {
              margin-right: 0.1rem;
            }
          }
          .mt {
            font-weight: 400;
            font-size: 14px;
            color: #a3b2cf;
            line-height: 18px;
            margin-top: 0.27rem;
            .note {
              color: $active-color;
              font-size: bold;
            }
          }
        }
      }
    }
  }
}

//礼品卡
.gift-container {
  border: 2px solid blue;
  height: 6.7rem;

  .gift-list {
    display: grid;
    grid-template-columns: repeat(5, 1.74rem);
    row-gap: 0.18rem;
    column-gap: 0.1rem;
    .gift {
      // border: 2px solid yellow;
      width: 1.74rem;
      height: 1.96rem;
      img {
        width: inherit;
      }
    }
  }
  .gift-info {
    font-weight: 400;
    font-size: 0.16rem;
    color: #a3b2cf;
    margin: 0.3rem 0;
  }
  .promocode {
  }
}

//skrill
.skrill-container {
  border: 2px solid orange;
  height: 6.7rem;
  .block {
    border: 2px solid green;
    height: 3rem;
    background-color: #161c2a;
    border-radius: 0.1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.2rem;
    .info {
      margin-bottom: 0.66rem;
    }
    .btn {
      .ant-btn {
        width: 1.7rem;
        height: 0.46rem;
        background-color: $active-color;
        border-radius: 0.23rem;
        font-size: 0.2rem;
        font-weight: bold;
      }
    }
  }
}

.recharge-dialog {
  // border: 1px solid red;

  .recharge-modal {
    :deep(.ant-modal) {
      border: 2px solid yellow;
      width: 17rem;
      height: 8.2rem;
      background-color: #0e121e;
      // background-color: #ed51af;
      .ant-modal-content {
        background-color: #0e121e;
        // height: inherit;
      }
      .ant-modal-close {
        right: .4rem;
        top: .4rem;
        img {
          width: 0.16rem;
          // height: .16rem;
        }
      }
      .ant-modal-body {
        padding: 1rem 0.7rem 0 0.7rem;
        height: inherit;
        display: flex;
        // border: 2px solid skyblue;
      }
      .recharge-container {
        // background-color: #0E121E;
        display: flex;
        // border: 2px solid blue;
        .left,
        .right {
          border: 2px solid green;
        }
        .left {
          width: 6.2rem;
          .title {
            @extend %flex-all-center;
            justify-self: flex-start;
            .picon {
              width: 0.36rem;
              height: 0.36rem;
              margin-right: 0.26rem;
            }
            span {
              font-weight: bold;
              font-size: 0.24rem;
              color: #ffffff;
            }
          }
          .line {
            height: 0.01rem;
            margin-top: 0.28rem;
            margin-bottom: 0.3rem;
            background-color: #272d40;
          }
          .list-container {
            .list-title {
              font-weight: 400;
              font-size: 0.18rem;
              color: #687088;
              margin-bottom: 0.2rem;
            }
            .list-cards {
              display: grid;
              grid-template-columns: repeat(3, 2rem);
              grid-gap: 0.1rem;
              .card {
                height: 1.3rem;
                border: 1px solid #304b75;
                border-radius: 0.05rem;
                @extend %flex-all-center;
                .card-icon {
                  width: 0.96rem;
                  height: 0.6rem;
                }
                &:hover {
                  cursor: pointer;
                  border: none;
                  @extend .active-card;
                }
              }
            }
          }
        }
        .right {
          width: 9.1rem;
          height: 6rem;
          margin-left: 0.3rem;
        }
      }
    }
  }
}
</style>
