<template>
  <div class="back-bar">
    <div class="bar f-start" @click="back">
      <div class="back fwb fz16">
        <a-space>
          <left-outlined />
        </a-space>
        {{ $t('comeBack') }}</div>
    </div>
    <slot>
      <div class="box-name fwb" v-if="title">{{ title }}</div>
    </slot>

    <slot name="info"></slot>
    <div class="bar f-end" v-if="fair">
      <div class="ver">
        <img src="@/assets/images/systemicon/verify.png" alt="" />
        <div class="fwb">{{ $t('fairnessVerificationData') }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  LeftOutlined
} from "@ant-design/icons-vue";
import { useRouter } from "vue-router";
const router = useRouter();
function back() {
  router.back();
}
const props = defineProps(["title", "fair"]);
</script>
<style lang="scss" scoped>
//非首页 显示返回首页按钮
.back-bar {
  display: flex;
  // width: 100%;
  // position: absolute;
  margin: 0 1rem;
  padding-bottom: 0.54rem;
  justify-content: space-between;
  align-items: center;
  .bar {
    display: flex;
    // width: 3rem;
  }
  .f-start {
    justify-content: flex-start;
    cursor: pointer;
  }
  .f-end {
    justify-content: flex-end;
  }
  .back {
    width: 1.6rem;
    height: .44rem;
    background-color: #1b2132;
    color: #ffffff;
    border-radius: .05rem;
    @extend %flex-all-center;
  }
  .box-name {
    font-size: .48rem;
  }
  .ver {
    width: 2.6rem;
    height: .44rem;
    background-color: #16311d;
    color: $fair-color;
    border-radius: 22px;
    font-size: .16rem;
    font-weight: 400;
    @extend %flex-all-center;
    img {
      width: .18rem;
      height: .22rem;
      margin-right: .1rem;
    }
  }
}
</style>
