import calcBalance from "@/utils/calcBalance.js";

/**
 * 判断账户余额是否满足开箱
 * @param {*} u userStore
 * @returns 
 */
export default function isBalanceEnough(u,proxy,price) {
  console.log("isBalacneEnough");
  if (!u.isToken) return false; //未登录重置用户数据
  const result = calcBalance(u.userInfo.bean, u.userInfo.diamond, price);
  if (!result) {
    proxy.$notice("f", { desc: "钻石金币不足,请充值" });
    return false;
  }
  if (result == "bc") {
    //补差
  }
  if (result == "useDiamond") {
    //使用钻石开箱

    return "useDiamond";
  }
  if (result) {
    //金币充足,直接开箱

    return true;
  }
}