import computeNumber from "@/utils/calcTotal.js";
/**
 * 开箱后手动计算余额,写入持久化
 * @param {*} paytype 开箱支付类型 bean:金币支付  diamond:金币+钻石支付  exchange 分解
 * @param {*} store userStore
 * @param {*} totalprice 开箱总价格
 */
export default function getAccountBalance(paytype, store = u, totalprice = 0) {
  //开箱前  账户余额  store.userInfo.bean   store.userInfo.diamond
  // console.log('更新');
  store.$patch((state) => {
    //金币充足  用金币开箱
    if (paytype == true) {
      state.userInfo.bean = computeNumber(state.userInfo.bean, "-", totalprice).result;
    }
    if (paytype == "useDiamond") {
      //1减去金币
      const cha = computeNumber(totalprice, "-", state.userInfo.bean).result;
      //2减去钻石
      state.userInfo.bean = 0;
      state.userInfo.diamond = computeNumber(state.userInfo.diamond, "-", cha);
    }

    //卖出 回收  分解
    if (paytype == "recycle") {
      state.userInfo.bean = computeNumber(state.userInfo.bean, "+", totalprice).result;
    }
    if (paytype == "exchange") {
      state.userInfo.bean = computeNumber(state.userInfo.bean, "+", totalprice).result;
    }
    //对战输家加奖励0.01
    if (paytype == "addBonus") {
      state.userInfo.bean = computeNumber(state.userInfo.bean, "+", totalprice).result;
    }
  });
}