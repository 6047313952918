//对战
import { getAction, postAction, menuGetAction } from "@/request/menage.js";
export default {

  /**
   * 对战列表
   * @param {*} page:页面 
   * @returns 
   */
  arenaList: (params) => getAction('/arena/list', params),

  /**
   * 加入盒子竞技频道
   * @param {*}  client_id webSocket频道id
   * @returns 
   */
  joinChannel: (params) => postAction('/arena/join-channel', params),

  /**
   * 创盒子对战
   * @param {*} user_num 对战人数 
   * @param {*} pattern 模式 minus:反转房(开出奖励最少得赢) plus:普通房(开出最多的赢) share 共享模式 
   * @param {*} type 默认0  1为 2vs2  
   * @param {*} box 对战箱子 传入数组,最高限制30个id 
   * @param {*} allow:是否允许机器人加入（0不允许 1允许）
   * @returns 
   */
  createArena: (params) => postAction('/arena/create', params),

  /**
   * 对战详情
   * @param {*} game_arena_id 对战id 
   * @returns 
   */
  arenaDetail: (params) => getAction('/arena/detail', params),

  /**
   * 获取服务器时间
   * @returns 服务器时间-秒数
   */
  getServerTime:()=>getAction('/getServerTime '),

  /**
   * 加入对战
   * @param {*} game_arena_id 对战id 
   * @param {*} seat 座位 座位号从1开始  第一个座位为1  第二个座位为2 以此类推
   * @returns 
   */
  joinArena: (params) => postAction('/arena/join', params),

  /**
   * 对战操作
   * @param {*} game_arena_id 对战id 
   * @param {*} status 状态  默认 0-站起  1-准备 3-退出 2允许机器人加入
   * @returns 
   */
  battleOpearte: (params) => postAction('/arena/operate', params),

  /**
   * 对战结束调用
   * @param {*} game_arena_id 对战房间id 
   * @returns 
   */
  endBattleGame:(params)=>postAction('/arena/endGame',params),

  /**
   * 对战箱子列表
   * @param {*} tag 标签的id值  传空则为全部  
   * @returns 
   */
  areaBoxList: (params) => getAction('/arena/box-list', params),

  /**
   * 对战历史记录
   * @param {*} page 页码
   * @returns 
   */
  arenaRecord: (params) => getAction('/arena/history', params),

  /**
   * 我的对战历史记录
   * @param {*} page 页码 
   * @returns 
   */
  arenaMeRecord: (params) => getAction('/arena/me-history', params),

  /**
   * 我的最佳对战记录
   * @returns 返回最佳对战记录
   */
  bestArenaRecord: () => getAction('/arena/best-history'),










}